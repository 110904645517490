export default [
  {
    path: '/user',
    name: 'user',
    meta: {
      icon: 'md-albums',
      title: '系统管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'group',
        name: 'group_management',
        meta: {
          icon: 'ios-people',
          title: '机构管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/group/index')
      },
      {
        path: 'user',
        name: 'user_management',
        meta: {
          icon: 'ios-person',
          title: '用户管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/user/index')
      },
      {
        path: 'menu',
        name: 'menu_management',
        meta: {
          icon: 'ios-grid',
          title: '菜单管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/menu/index')
      },
      {
        path: 'role',
        name: 'role_management',
        meta: {
          icon: 'ios-bowtie',
          title: '角色管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/role/index')
      },
      {
        path: 'area',
        name: 'area_management',
        meta: {
          icon: 'ios-jet',
          title: '地区表管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/area/index')
      },
      {
        path: 'jobInfo',
        name: 'jobInfo_management',
        meta: {
          icon: 'ios-clock',
          title: '调度任务',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/jobInfo/index')
      }
    ]
  },
  {
    path: '/config',
    name: 'config',
    meta: {
      icon: 'md-albums',
      title: '系统配置',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'config_management',
        meta: {
          icon: 'md-settings',
          title: '系统配置',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/config/index')
      }
    ]
  },
  {
    path: '/appUser',
    name: 'appUser',
    meta: {
      icon: 'md-albums',
      title: '门户用户管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'appUser_management',
        meta: {
          icon: 'ios-people',
          title: '门户用户管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/appUser/index')
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      icon: 'md-albums',
      title: '公告管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'notice_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '公告管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/notice/index')
      }
    ]
  },
  {
    path: '/withdrawDeposit',
    name: 'withdrawDeposit',
    meta: {
      icon: 'md-albums',
      title: '提现管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'withdrawDeposit_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '提现管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/withdrawDeposit/index')
      }
    ]
  },
  {
    path: '/topUp',
    name: 'topUp',
    meta: {
      icon: 'md-albums',
      title: '充值管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'topUp_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '充值管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/topUp/index')
      }
    ]
  },
  {
    path: '/tradingFloor',
    name: 'tradingFloor',
    meta: {
      icon: 'md-albums',
      title: '交易大厅管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'tradingFloor_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '交易大厅管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/tradingFloor/index')
      }
    ]
  },
  {
    path: '/sell',
    name: 'sell',
    meta: {
      icon: 'md-albums',
      title: '我要卖管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'sell_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '我要卖管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/sell/index')
      }
    ]
  },
  {
    path: '/euroAcceptanceRecord',
    name: 'euroAcceptanceRecord',
    meta: {
      icon: 'md-albums',
      title: '欧元承兑记录管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'euroAcceptanceRecord_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '欧元承兑记录管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/euroAcceptanceRecord/index')
      }
    ]
  },
  {
    path: '/bank',
    name: 'bank',
    meta: {
      icon: 'md-albums',
      title: '银行卡绑定管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'bank_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '银行卡绑定管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/bank/index')
      }
    ]
  },
  {
    path: '/currencyParameter',
    name: 'currencyParameter',
    meta: {
      icon: 'md-albums',
      title: '货币参数管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'currencyParameter_management',
        meta: {
          icon: 'md-radio-button-off',
          title: '货币参数管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/currencyParameter/index')
      }
    ]
  }
]
