import axios from 'axios'
import { request } from './request'
import config from '@/config'
import router from '@/router'
import store from '@/store'
import { getRefreshToken, getToken } from './util'
import { errCode } from './error-content'
import { httpStatus } from './http-status'
import { Notice } from 'view-ui-plus'
import { MENU_DATA, TABLE_DATA } from '@/constant'

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? config.baseUrl.dev
    : config.baseUrl.pro

export const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
  timeout: 10000
})

const errorHandle = async (err) => {
  // console.log('TCL: errorHandle -> err', err)
  if (err.response && err.response !== undefined) {
    if (
      err.response.status === httpStatus.UNAUTHORIZED.key &&
      err.response.data.code === errCode.TOKEN_ERROR.key
    ) {
      const refreshToken = getRefreshToken()
      // token已经过期
      // 需要请求refreshToken接口
      try {
        const result = await instance.post('/refreshToken', null, {
          params: { refreshToken: refreshToken },
          headers: {
            Authorization: 'Bearer ' + getToken()
          }
        })
        if (result) {
          store.commit('user/setToken', result.data.accessToken)
          // 1. 成功 -> 重新发起请求 -> 参数
          return request(err.config.url, err.config.method, err.config.params)
        }
      } catch (error) {
        // 2. 失败 -> token全失效需要用户重新登录
        store.commit('user/setToken', '')
        store.commit('user/setRefreshToken', '')
        store.commit('user/setAccess', [])
        store.commit('user/setUserDetail', {})
        store.commit('user/setRoutes', [])
        localStorage.removeItem(MENU_DATA)
        localStorage.removeItem(TABLE_DATA)
        router.push({
          name: 'login',
          query: {
            redirect: router.currentRoute.value.fullPath
          }
        })
        return false
      }
    } else if (
      err.response.status === httpStatus.FORBIDDEN.key &&
      err.response.data.code === errCode.UNAUTHENTICATED_ERROR.key
    ) {
      //
      Notice.warning({
        title: '用户未授权',
        desc:
          'api:【' +
          err.response.data.request +
          '】' +
          err.response.data.msg +
          ',请联系管理员'
      })
      return false
    } else if (
      err.response.status === httpStatus.INTERNAL_SERVER_ERROR.key &&
      err.response.data.code === errCode.PARAMETER_ERROR.key
    ) {
      // 请求入参错误
      Notice.warning({
        title: '请求入参错误',
        desc:
          'api:【' + err.response.data.request + '】' + err.response.data.msg
      })
      return err.response.data
    } else {
      if (err.response.status === httpStatus.FORBIDDEN.key) {
        Notice.warning({
          title: '用户未授权',
          desc:
            'api:【' +
            err.response.config.url +
            '】' +
            '用户未被授权,请联系管理员'
        })
      } else {
        // 其他异常
        Notice.warning({
          title: '系统异常',
          desc:
            'api:【' + err.response.data.request + '】' + err.response.data.msg
        })
      }
      return false
    }
  }
}

export default errorHandle
