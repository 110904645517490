import Dispatch from '@/libs/dipatch'

/** 管理系统管理用户表相关 */
export const userDispatch = new Dispatch({
  getUserList: '/sys/user/list',
  deleteUserById: '/sys/user/delete',
  checkUsername: '/sys/user/checkUsername',
  checkMobile: '/sys/user/checkMobile',
  checkEmail: '/sys/user/checkEmail',
  updateUserById: '/sys/user/update',
  addUser: '/register',
  updateUserBatchById: '/sys/user/batchUpdate',
  getUserRole: '/sys/user/getUserRole',
  updateUserRole: '/sys/user/updateUserRole',
  checkPassword: '/sys/user/checkPassword',
  export: '/sys/user/export',
  exportTemplate: '/sys/user/exportTemplate',
  importData: '/sys/user/importData',
  updatePassword: '/updatePassword'
})

/** 管理机构管理表相关 */
export const groupDispatch = new Dispatch({
  getGroupList: '/sys/group/list',
  queryGroupList: '/sys/group/queryGroupTreeList',
  getGroupDetail: '/sys/group/info',
  deleteGroupById: '/sys/group/delete',
  updateGroupById: '/sys/group/update',
  addGroup: '/sys/group/save',
  getGroupUser: '/sys/group/getGroupUser',
  updateGroupUser: '/sys/group/updateGroupUser',
  getGroupRole: '/sys/group/getGroupRole',
  updateGroupRole: '/sys/group/updateGroupRole'
})

/** 管理菜单管理表相关 */
export const menuDispatch = new Dispatch({
  deleteMenu: '/sys/menu/delete',
  check: '/sys/menu/check',
  updateMenuById: '/sys/menu/update',
  addMenu: '/sys/menu/save',
  getTreeList: '/sys/menu/tree',
  getCatalogList: '/sys/menu/getCatalog',
  transfer: '/sys/menu/transfer'
})

/** 管理角色管理表相关 */
export const roleDispatch = new Dispatch({
  getRoleList: '/sys/role/list',
  deleteRoleById: '/sys/role/delete',
  updateRoleById: '/sys/role/update',
  addRole: '/sys/role/save'
})

/** 管理角色管理表相关 */
export const fileDispatch = new Dispatch({
  uploadImg: '/uploadImg'
})

/** 管理调度任务信息相关 */
export const jobInfoDispatch = new Dispatch({
  getJobInfoList: '/sys/jobInfo/list',
  deleteJobInfoById: '/sys/jobInfo/delete',
  check: '/sys/jobInfo/check',
  updateJobInfoById: '/sys/jobInfo/update',
  addJobInfo: '/sys/jobInfo/save',
  updateJobInfoBatchById: '/sys/jobInfo/batchUpdate',
  export: '/sys/jobInfo/export',
  exportTemplate: '/sys/jobInfo/exportTemplate',
  importData: '/sys/jobInfo/importData',
  refreshJob: '/sys/jobInfo/refreshJob',
  refreshAll: '/sys/jobInfo/refreshAll',
  getJobLogList: '/sys/jobInfo/log',
  deleteJobLog: '/sys/jobInfo/deleteLog'
})

/** 管理地区表相关 */
export const areaDispatch = new Dispatch({
  getAreaList: '/sys/area/list',
  getAreaDetail: '/sys/area/info',
  deleteAreaById: '/sys/area/delete',
  check: '/sys/area/check',
  updateAreaById: '/sys/area/update',
  addArea: '/sys/area/save',
  queryAreaList: '/sys/area/queryAreaTreeList'
})

/** 管理门户用户相关 */
export const appUserDispatch = new Dispatch({
  getAppUserList: '/appuser/list',
  getAppUserDetail: '/appuser/info',
  deleteAppUserById: '/appuser/delete',
  check: '/appuser/check',
  promotionCodeCheck: '/appuser/promotionCodeCheck',
  updateAppUserById: '/appuser/update',
  addAppUser: '/appuser/save',
  updateAppUserBatchById: '/appuser/batchUpdate',
  export: '/appuser/export',
  exportTemplate: '/appuser/exportTemplate',
  importData: '/appuser/importData'
})

/** 管理公告相关 */
export const noticeDispatch = new Dispatch({
  getNoticeList: '/notice/list',
  deleteNoticeById: '/notice/delete',
  check: '/notice/check',
  updateNoticeById: '/notice/update',
  addNotice: '/notice/save',
  updateNoticeBatchById: '/notice/batchUpdate',
  export: '/notice/export',
  exportTemplate: '/notice/exportTemplate',
  importData: '/notice/importData'
})

/** 管理提现相关 */
export const withdrawDepositDispatch = new Dispatch({
  getWithdrawDepositList: '/withdraw/list',
  getWaitList: '/withdraw/getWaitList',
  deleteWithdrawDepositById: '/withdraw/delete',
  updateWithdrawDepositById: '/withdraw/update',
  addWithdrawDeposit: '/withdraw/save',
  updateWithdrawDepositBatchById: '/withdraw/batchUpdate',
  export: '/withdraw/export',
  exportTemplate: '/withdraw/exportTemplate',
  importData: '/withdraw/importData'
})

/** 管理充值相关 */
export const topUpDispatch = new Dispatch({
  getTopUpList: '/topup/list',
  getWaitList: '/topup/getWaitList',
  deleteTopUpById: '/topup/delete',
  updateTopUpById: '/topup/update',
  addTopUp: '/topup/save',
  updateTopUpBatchById: '/topup/batchUpdate',
  export: '/topup/export',
  exportTemplate: '/topup/exportTemplate',
  importData: '/topup/importData'
})

/** 管理交易大厅相关 */
export const tradingFloorDispatch = new Dispatch({
  getTradingFloorList: '/trading/list',
  getTradingFloorDetail: '/trading/info',
  deleteTradingFloorById: '/trading/delete',
  updateTradingFloorById: '/trading/update',
  addTradingFloor: '/trading/save',
  updateTradingFloorBatchById: '/trading/batchUpdate',
  export: '/trading/export',
  exportTemplate: '/trading/exportTemplate',
  importData: '/trading/importData'
})

/** 管理我要卖相关 */
export const sellDispatch = new Dispatch({
  getSellList: '/sell/list',
  getWaitList: '/sell/getWaitList',
  deleteSellById: '/sell/delete',
  updateSellById: '/sell/update',
  addSell: '/sell/save',
  updateSellBatchById: '/sell/batchUpdate',
  export: '/sell/export',
  exportTemplate: '/sell/exportTemplate',
  importData: '/sell/importData'
})

/** 管理欧元承兑记录相关 */
export const euroAcceptanceRecordDispatch = new Dispatch({
  getEuroAcceptanceRecordList: '/euro/list',
  getWaitList: '/euro/getWaitList',
  deleteEuroAcceptanceRecordById: '/euro/delete',
  updateEuroAcceptanceRecordById: '/euro/update',
  addEuroAcceptanceRecord: '/euro/save',
  updateEuroAcceptanceRecordBatchById: '/euro/batchUpdate',
  export: '/euro/export',
  exportTemplate: '/euro/exportTemplate',
  importData: '/euro/importData'
})

/** 管理银行卡绑定相关 */
export const bankDispatch = new Dispatch({
  getBankList: '/bank/list',
  deleteBankById: '/bank/delete',
  check: '/bank/check',
  updateBankById: '/bank/update',
  addBank: '/bank/save',
  updateBankBatchById: '/bank/batchUpdate',
  export: '/bank/export',
  exportTemplate: '/bank/exportTemplate',
  importData: '/bank/importData'
})

/** 管理货币参数相关 */
export const currencyParameterDispatch = new Dispatch({
  getCurrencyParameterList: '/currency/list',
  deleteCurrencyParameterById: '/currency/delete',
  check: '/currency/check',
  updateCurrencyParameterById: '/currency/update',
  addCurrencyParameter: '/currency/save',
  updateCurrencyParameterBatchById: '/currency/batchUpdate',
  export: '/currency/export',
  exportTemplate: '/currency/exportTemplate',
  importData: '/currency/importData'
})

/** 邮箱配置 */
export const emailSettingDispatch = new Dispatch({
  getEmailSettingDetail: '/email/info',
  updateEmailSettingById: '/email/update'
})

/** app配置 */
export const configurationDispatch = new Dispatch({
  getConfigurationDetail: '/configuration/info',
  updateConfigurationById: '/configuration/update'
})
